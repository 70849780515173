import React, { useEffect, useState } from 'react';
import CommonSection from '../shared/CommonSection';
import '../styles/Tour.css';
import TourCard from './../shared/TourCard';
import SearchBar from './../shared/SearchBar';
import { Container, Row, Col, Spinner } from 'reactstrap';
import Newsletter from '../shared/Newsletter';
import useFetch from '../../hook/useFetch';
import { BASE_URL } from '../../utils/config';

const Tour = () => {
  // State variables
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(0);

  const { data: tours, loading, error } = useFetch(`${BASE_URL}/tours?page=${page}`);
  const { data: tourCount } = useFetch(`${BASE_URL}/tours/search/getTourCount`);

  useEffect(() => {
    // Calculate the number of pages based on the data
    const pages = Math.ceil(tourCount / 8);
    setPageCount(pages);
    window.scrollTo(0,0)
  }, [page, tourCount, tours]);

  return (
    <>
      <CommonSection title={'All Tours'} />
      <section>
        <Container>
          <Row>
            <SearchBar />
          </Row>
        </Container>
      </section>
      <section className="pt-0">
        <Container>

          {loading && <h4 className='text-center pt-5'>Loading......</h4>}
          {error && <h4 className='text-center pt-5'>{error}</h4>}
          {!loading && !error && (
            <Row>
            {tours?.map((tour) => (
              <Col lg="3" md='6' sm='6' className="mb-4" key={tour._id}>
                <TourCard tour={tour} />
              </Col>
            ))}
            <Col lg="12">
              <div className="pagination d-flex align-items-center justify-content-center mt-4 gap-3">
                {/* Render page numbers */}
                {[...Array(pageCount).keys()].map((number) => (
                  <span
                    key={number}
                    onClick={() => setPage(number)}
                    className={page === number ? 'active__page' : ''}
                  >
                    {number + 1}
                  </span>
                ))}
              </div>
            </Col>
          </Row>
          )}
        </Container>
      </section>
      <Newsletter />
    </>
  );
};

export default Tour;
