

import React from 'react'

const Subtitle = ({ Subtitle}) => {
  return (
    <h3 className="section__subtitle">{Subtitle}</h3>
  )
}

export default Subtitle