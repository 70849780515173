import "./App.css";
import Layout from "./components/layout/Layout";

function App() {
  return <>
    <Layout/>
  </>
}

export default App;
